import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import NewsletterBanner from "../../components/newsletter-banner";
import TeamMember from "../../components/team-member";

const OurPeoplePage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Our-People-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			rosamundImg: wpMediaItem(title: { eq: "Rosamund" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			jocelynImg: wpMediaItem(title: { eq: "Jocelyn-Cockburn" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			stephenImg: wpMediaItem(title: { eq: "Stephen" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			annImg: wpMediaItem(title: { eq: "Ann" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			andreaImg: wpMediaItem(title: { eq: "Andrea" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			jenImg: wpMediaItem(title: { eq: "Jennifer" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			sharonImg: wpMediaItem(title: { eq: "Sharon" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			jossImg: wpMediaItem(title: { eq: "Joss" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			barbImg: wpMediaItem(title: { eq: "Barbara" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Our People",
				item: {
					url: `${siteUrl}/about-us/our-people`,
					id: `${siteUrl}/about-us/our-people`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Our People | The Ella Roberta Foundation"
				description="Rosamund is the founder, director, and trustee of the Foundation as well as Ella’s mum. She is one of the most prominent advocates for clean air worldwide, a powerful voice in the air pollution debate. She started campaigning as soon as she realised its role in her daughter’s death."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/our-people`,
					title: "Our People | The Ella Roberta Foundation",
					description:
						"Rosamund is the founder, director, and trustee of the Foundation as well as Ella’s mum. She is one of the most prominent advocates for clean air worldwide, a powerful voice in the air pollution debate. She started campaigning as soon as she realised its role in her daughter’s death.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<div className="bg-background">
					<Hero
						title={
							<span>
								OUR
								<br />
								PEOPLE
							</span>
						}
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
					<section className="bg-primary py-5 pt-lg-8">
						<Container>
							<Row className="justify-content-center">
								<Col lg={9}>
									<p className="text-white fs-3  text-center position-relative">
										<span
											style={{ left: "-2.6rem", top: "-2rem" }}
											className="display-2 d-none d-lg-inline-block me-5 position-absolute gobold-thin text-uppercase "
										>
											“
										</span>
										<span className="d-lg-none gobold-thin">“</span>
										Rosamund is one of the world’s most successful campaigners
										on clean air. Her personal story, combined with her
										determination, drive and unrelenting commitment to improving
										air quality, is awesome to see. She has that rare ability to
										connect at a deeply personal level with a wide range of
										audiences including parents, campaigners, film stars and
										world leaders. She is a powerful ambassador for everyone
										impacted by air pollution.
										<span className="d-lg-none gobold-thin">”</span>
										<span
											style={{ right: "-2.8rem", top: "-2rem" }}
											className="display-2 d-none d-lg-inline-block position-absolute gobold-thin text-uppercase"
										>
											”
										</span>
									</p>
									<p className="text-center text-white fs-5">
										Jane Burston, Executive Director, Clean Air Fund
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className=" py-5 py-lg-8">
						<Container>
							<Row className="">
								<Col>
									<GatsbyImage
										image={
											data.rosamundImg?.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={data.rosamundImg.altText}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col className="text-start">
									<h2 className="fs-1  py-5">ROSAMUND ADOO-KISSI-DEBRAH</h2>
									<p>
										Rosamund is the founder, director, and trustee of the
										Foundation as well as Ella’s mum. She is one of the most
										prominent advocates for clean air worldwide, a powerful
										voice in the air pollution debate. She started campaigning
										as soon as she realised its role in her daughter’s death.
										Her determination to highlight the human cost of air
										pollution has led to her addressing audiences all over the
										world; from her recent TED Talk to conversations with other
										eminent environmentalist and health advocates, such as
										Christiana Figueres, Dr Maria Neira and Arnold
										Schwarzeneger. Her work frequently involves lobbying
										politicians, as well as medical professionals, for more
										education and stronger legislation when it comes to air
										pollution.
									</p>
									<p>
										However, Rosamund is more than just a powerful voice. She
										and her legal team made history when a Coroner found a
										direct link between her daughter’s death and the air quality
										near her home, 25 metres from the south circular, one of
										London’s busiest roads. Ella’s death certificate was amended
										to have air pollution listed as cause of death, potentially
										changing the legal landscape to enable other people to do
										the same.
									</p>
									<p>
										Rosamund is now a leading light in the global fight against
										air pollution, a position recognised by the World Health
										Organisation, who made her a BreatheLife Ambassador. She
										uses her experience and position to highlight the inequality
										of those affected by air pollution. She is also an Honorary
										Fellow of the British Science Association and was named
										among You Magazine’s 21 Most Extraordinary Women of 2021,
										British Vogue’s 25 Extraordinary Women of 2021, The Times’
										Green Power List 2021 and BBC Radio 4 Woman’s Hour Power
										List 2020.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="bg-primary py-5 text-center">
						<Container>
							<Row>
								<Col>
									<h2 className="display-3 text-white gobold-reg pb-lg-5">
										THE
										<br className="d-none d-lg-block" /> TEAM
									</h2>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-8">
						<Container>
							{/*<TeamMember
								image={
									data.jocelynImg?.localFile.childImageSharp.gatsbyImageData
								}
								imageAlt={data.jocelynImg?.altText}
								title="JOCELYN COCKBURN"
								description={
									<span>
										Jocelyn is a human rights lawyer, and a partner in the civil
										liberties and human rights department at{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.hja.net/legal-services/environmental-justice/"
										>
											Hodge Jones & Allen solicitors
										</a>
										. She represented Rosamund at the inquest into Ella's death
										in 2020 in which air pollution was officially recognised as
										one of the causes of Ella's death.
									</span>
								}
							/>*/}
							<TeamMember
								image={
									data.stephenImg?.localFile.childImageSharp.gatsbyImageData
								}
								imageAlt={data.stephenImg?.altText}
								title="PROFESSOR STEPHEN HOLGATE"
								description={
									<span>
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.southampton.ac.uk/medicine/about/staff/sth.page"
										>
											Professor Sir Stephen Holgate
										</a>
										, a consultant respiratory physician at the University of
										Southampton, was the expert witness at Ella’s second
										inquest, where pollution was found to have contributed to,
										and exacerbated her asthma. He has been a supporter of the
										Foundation and Rosamund, since he started working with the
										team in 2017.
									</span>
								}
							/>
							<TeamMember
								image={data.annImg?.localFile.childImageSharp.gatsbyImageData}
								imageAlt={data.annImg?.altText}
								title="ANN POTTER"
								description="Ann is a retired head teacher, with over 30 years experience in secondary schools. She is the Foundation’s secretary, and believes “it’s every child’s right to grow up in a healthy environment. Children can only make the most of their educational opportunities if they are in good physical health.” She became involved in the Foundation after meeting Ella’s mother and siblings at a performing arts school that Ella had also attended."
							/>
							<TeamMember
								image={
									data.andreaImg?.localFile.childImageSharp.gatsbyImageData
								}
								imageAlt={data.andreaImg?.altText}
								title="ANDREA CAREY FULLER"
								description="Andrea joined the team in November 2021 as Chair. Her background is providing independent advocacy to a wide range of vulnerable/disabled adults and children, enabling them to have their voices heard. She’s worked with organisations like the British Red Cross and has experience of establishing and running an independent charity. She has campaigned with Rosamund to highlight the dangerous levels of air pollution in and around London."
							/>
							{/* <TeamMember
								image={data.jenImg?.localFile.childImageSharp.gatsbyImageData}
								imageAlt={data.jenImg?.altText}
								title="JENNIFER DAVIS"
								description="Jenni is the Foundation’s treasurer, a chartered tax advisor, a partner in her accountancy firm, and a specialist in online accounting software. She joined the Trustees as Treasurer in 2021 after meeting Ella’s mum, Rosamund, and being impressed by her ability to turn the tragedy of her daughter’s death into something positive."
							/> */}
							<TeamMember
								image={
									data.sharonImg?.localFile.childImageSharp.gatsbyImageData
								}
								imageAlt={data.sharonImg?.altText}
								title="SHARON FARNLEY"
								description="Sharon’s daughter Eloise was in Ella’s class at primary school in Catford. They were friends as well as classmates. Sharon and Rosamund were governors together on the same school’s board. Sharon is still there, as the Chair, and she works for telecommunications regulator, Ofcom. She became a Trustee in 2015 and is “proud to support the work that Rosamund has been doing; making the air we breathe safe for everyone.”"
							/>
							<TeamMember
								image={data.jossImg?.localFile.childImageSharp.gatsbyImageData}
								imageAlt={data.jossImg?.altText}
								title="JOSS TAYLOR"
								description="Joss is a Trustee as well as head of enterprise and placemaking for Bow Arts Trust. Using his experience, he is helping the Foundation make the invisible visible, by co-ordinating a sculpture of Ella made from a material that absorbs air pollution, at the same time as drawing attention to the air pollution debate. “Having grown up in South East London, air pollution has always been on my doorstep.”"
							/>
							<TeamMember
								image={data.barbImg?.localFile.childImageSharp.gatsbyImageData}
								imageAlt={data.barbImg?.altText}
								title="BARBARA GRAY"
								description={
									<span>
										Barbara Gray is our newest trustee. She has been involved in
										the community sector for 25 years, and is a strong advocate
										for Lewisham, where she worked in the council for 15 years.
										Her current role is CEO of{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://urbandandelion.co.uk/"
										>
											Urban Dandelion
										</a>
										. “The Ella Roberta Foundation has had a global impact that
										will benefit many people’s lives and fits with the work of
										Urban Dandelion, which inspires communities to bring about
										the changes they want to see.”
									</span>
								}
							/>
						</Container>
					</section>
					<NewsletterBanner />
				</div>
			</Layout>
		</>
	);
};

export default OurPeoplePage;
