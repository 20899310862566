import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { GatsbyImage } from "gatsby-plugin-image";

const TeamMember = ({ title, image, description, imageAlt }) => {
	return (
		<Row className="align-items-center align-items-center text-center text-lg-start gx-lg-5 mb-lg-7 mb-5">
			<Col className="mb-5 mb-lg-0 text-center" lg={4}>
				<div style={{ borderRadius: "100%" }}>
					<GatsbyImage
						style={{ borderRadius: "100%" }}
						className=" round-image"
						image={image}
						alt={imageAlt}
					/>
				</div>
			</Col>
			<Col lg={8}>
				<h2 className="mb-4">{title}</h2>
				<p>{description}</p>
			</Col>
		</Row>
	);
};

export default TeamMember;
